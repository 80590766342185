<template>
  <add-user @createUser="createUser($event)" @editUser="editUser($event)" />
</template>

<script>
import AddUser from "../../components/Users/AddUser.vue";

export default {
  components: {
    AddUser,
  },
  methods: {
    async createUser(req_body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("addNewUser", req_body);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "User created successfully!",
          group: "success",
        });

        await this.$router.push("/users-management/users");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async editUser(req_body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("updateUser", req_body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "User updated successfully!",
          group: "success",
        });

        this.$router.push("/users-management/users");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>