<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <v-row>
      <v-col cols="7">
        <general-card class="pa-5 mt-5 three-quarter-height">
          <h6 class="mb-2">User Name</h6>
          <v-text-field dense outlined label="User Name" class="mb-4" v-model="name" required
            :rules="[(v) => !!v || 'Set a username to proceed!']"></v-text-field>

          <h6 class="mb-2">User Email</h6>
          <v-text-field dense outlined label="Email" class="mb-4" v-model="email" type="email" required
            :rules="[(v) => !!v || 'Set an email to proceed!']"></v-text-field>

          <h6 class="mb-2">User Phone Number</h6>
          <v-text-field dense outlined label="Phone Number" class="mb-4" v-model="phone_number" required
            :rules="[(v) => !!v || 'Set a phone number to proceed!']"></v-text-field>
          <h6 class="mb-2" v-if="modifyPassword"> Change Password</h6>
          <v-checkbox class="mb-4" v-if="modifyPassword" dense v-model="addPassword"></v-checkbox>

          <h6 class="mb-2" v-if="addPassword">Password</h6>
          <v-text-field v-if="addPassword" dense outlined label="Password" class="mb-4" v-model="password" required
            :rules="[(v) => !!v || 'Set a password to proceed!']"></v-text-field>

          <div v-if="superAdmin">
            <h6 class="mb-2">Select Agency</h6>
            <v-select outlined dense label="Select Agency" class="mb-4" :items="agencies" item-text="agency_name"
              item-value="id" v-model="selected_agency"></v-select>

            <h4 class="mb-2 mt-auto">Roles & Permissions</h4>

            <h6 class="my-2 ">Set Roles</h6>
            <v-combobox class="my-4" dense label="Choose roles" :items="roles" item-text="name" item-value="id" multiple
              deletable-chips small-chips clearable chips v-model="selected_roles"></v-combobox>

          </div>

          <h6 class="my-2 ">Set Permissions</h6>
          <v-combobox class="my-4" dense label="Choose permissions" :items="permissions" item-text="name" item-value="id"
            multiple deletable-chips small-chips clearable chips v-model="selected_permissions"></v-combobox>


          <v-card-actions class="d-flex justify-end pt-16">
            <v-btn color="primary" small @click="handleSubmit">Submit</v-btn>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "./../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    name: "",
    email: "",
    phone_number: "",
    password: "",
    selected_permissions: [],
    selected_roles: [],
    selected_agency: "",
    addPassword: true,
    modifyPassword: false
  }),
  components: {
    GeneralCard,
  },
  methods: {
    async validate() {
      await this.$refs.form.validate();
    },
    async handleSubmit() {
      await this.validate();
      if (!this.valid) return;

      if (this.$route.name == "add-user") {
        this.submit();
      } else {
        this.updateUser();
      }
    },
    submit() {
      let permissions = this.selected_permissions.map(function (permission) {
        return permission.id;
      });
      let roles = this.selected_roles.map(function (role) {
        return role.id;
      });
      let body = {
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        password: this.password,
        roles: roles,
        permissions: permissions,
        agency_id: this.selected_agency
      };
      this.$emit("createUser", body);
    },
    updateUser() {
      let permissions = this.selected_permissions.map(function (permission) {
        return permission.id;
      });
      let roles = this.selected_roles.map(function (role) {
        return role.id;
      });
      let body = {
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        roles: roles,
        permissions: permissions,
        agency_id: this.selected_agency
      };
      if (this.addPassword) {
        body.password = this.password;
      }
      let combined_data = {
        user_id: this.$route.params.id,
        body: body
      };
      this.$emit("editUser", combined_data);
    },

    //Get Permissions
    async getPermissions() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readPermissions");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    },
    //Get Roles
    async getRoles() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readRoles");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    },
    //Get Agencies
    async getAgencies() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readAllAgencies", 100);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    },
    async getUserData() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("getSingleUser", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    },
  },

  created() {
    if (this.$route.name == "update-user") {
      this.getUserData();
      this.addPassword = false;
      this.modifyPassword = true;
    }
  },
  computed: {
    single_user() {
      return this.$store.state.user;
    },
    permissions() {
      if (!this.$store.state.permissions.length) {
        this.getPermissions();
      }
      return this.$store.state.permissions;
    },
    roles() {
      if (!this.$store.state.roles.length) {
        this.getRoles();
      }
      return this.$store.state.roles;
    },
    agencies() {
      if (!this.$store.state.all_agencies.length) {
        this.getAgencies();
      }
      return this.$store.state.all_agencies;
    },

    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  watch: {
    single_user() {
      this.name = this.single_user.name;
      this.email = this.single_user.email;
      this.phone_number = this.single_user.phone_number;
      this.password = this.single_user.password;
      this.selected_permissions = this.single_user.permissions;
      this.selected_roles = this.single_user.roles;
      this.selected_agency = parseInt(this.single_user.agency_id);
    },
  },
};

</script>